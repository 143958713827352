<template>
  <!-- 横屏 -->
  <horizontalScreen class="v-game-course"> 
    <!-- <scaleWrap> -->
      <div class="home">
          <div class="word-container">
            <div :id="`word${index}`" class="word" v-for="(word, index) in showWordList.slice(0, 3)" :key="word.name">
              <img class="flower" src="https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game2-9/anime/type0/0.png"/>
              <div v-show="word.showIcon" class="icon" @click.stop.prevent="word.clicked ? null : wordClickHandler($event, word, index)">
                <div class="icon-img" :style="{backgroundImage: 'url(' + word.icon + ')'}"> 
                </div> 
              </div>
            </div>
          </div>
          <div class="word-container">
            <div :id="`word${index+3}`" class="word" v-for="(word, index) in showWordList.slice(3, 6)" :key="word.name">
              <img class="flower" src="https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game2-9/anime/type0/0.png"/>
              <div v-show="word.showIcon" class="icon" @click.stop.prevent="word.clicked ? null : wordClickHandler($event, word, index+3)">
                <div class="icon-img" :style="{backgroundImage: 'url(' + word.icon + ')'}"> 
                </div> 
              </div>
            </div>
          </div>
        
        <!-- <div class="word-container">
          <div @click.stop.prevent="word.clicked ? null : wordClickHandler($event, word, index+6)" :id="`word${index+6}`" class="word" v-for="(word, index) in showWordList.slice(6, 9)" :key="word.name">
            <img src="https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game2-9/anime/type0/0.png"/>
            <div v-show="word.showIcon" class="icon" @click.stop.prevent="word.clicked ? null : wordClickHandler($event, word, index+6)" :style="{backgroundImage: 'url(' + word.icon + ')'}"></div>
          </div>
        </div> -->
        <img id="person" src="https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game2-9/walk/0.png">
        <img id="partner" src="https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game2-9/clap/0.png">
        <!-- <img id="partner" src="@/assets/game2-9/clap/0.png"> -->
        <img class="logo" src="https://image.yuanyuanwenhua.com/literacy/static/img/allGame/logo.png"/>
        <div class="sound-box-top" :style="{right: height < 700 ? '10px' : null}">
           <img @click="backHandler" class="back" src="@/assets/back.png"/>
           <img @click.stop.prevent="reverseHandler" class="reverse" src="@/assets/reverse.png"/>
           <img @click="startHandler" class="voice" src="@/assets/voice.png"/>
        </div>
        <!-- <div class="sound-box-bottom"> -->
           <!-- <img @click="backHandler" class="back" src="@/assets/back.png"/> -->
           <!-- <img @click="reverseHandler" class="reverse" src="@/assets/reverse.png"/> -->
           <!-- <img @click.stop.prevent="startHandler" class="voice" src="@/assets/voice.png"/> -->
        <!-- </div> -->
        <audio id="myAudio" preload="auto"></audio>
        <audio id="failAudio" preload="auto">
          <source :src="this.failAudio">
        </audio>
        <audio id="hecaiAudio" preload="auto">
          <source :src="this.hecaiAudio">
        </audio>
        <audio id="successAudio" preload="auto">
          <source :src="this.successAudio">
        </audio>
        
        <Overlay class="mask-dialog" :show="showStartGameIcon">
          <img class="overlay-bg" src="https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game2-9/bg.png"/>
          <div class="sound-box-top" :style="{right: height < 700 ? '10px' : null}">
            <img @click="backHandler" class="back" src="@/assets/back.png"/>
            <!-- <img @click.stop.prevent="reverseHandler" class="reverse" src="@/assets/reverse.png"/>
            <img @click="startHandler" class="voice" src="@/assets/voice.png"/> -->
          </div>
          <div class="mask-title">{{index}} {{gameName}}</div>
          <img @click="startGameHandler(true)" class="start" src="https://image.yuanyuanwenhua.com/literacy/static/img/allGame/btn-start.png"/>
        </Overlay>
      </div>
    <!-- </scaleWrap> -->
  </horizontalScreen>
</template>
<script>
import anime from 'animejs/lib/anime.es.js'
import horizontalScreen from '@/components/horizontal-screen'
// import scaleWrap from '@/components/scale-wrap'
import {shuffle, isEmpty, cloneDeep} from 'lodash'
import { Overlay } from 'vant'
import mixin from '../mixin'
import {Howl} from 'howler'
import {isIOS} from '@/utils/env'

export default {
  name: 'GameCourse',
  mixins: [mixin],
  components: {
    // , scaleWrap
    horizontalScreen, Overlay
  },
  created () {
    this.hecaiAudioVoice = new Howl({
      src: [this.hecaiAudio],
      html5: isIOS
    });
    this.successAudioVoice = new Howl({
      src: [this.successAudio],
      html5: isIOS
    });
  },
  beforeDestroy () {
    this.hecaiAudioVoice.pause()
    this.successAudioVoice.pause()
  },
  data() {
    return {
      hecaiAudioVoice: null,
      successAudioVoice: null,
      personTarget: {
        right: null,
        bottom: null
      },
      index: this.$route.query.index,
      id: this.$route.query.id,
      gameName: null,
      hecaiAudio: `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game2-9/sounds/chui.mp3`,
      successAudio: `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game2-9/sounds/pgy.mp3`,
      clickAudio: require(`@/assets/sounds/click.mp3`),
      failAudio: require(`@/assets/sounds/fail.mp3`),
      clapImgLength: 20, // 吹气
      boardImgLength: 20, // 花苞
      workImgLength: 12, // 小人走路动画
      digImgLength: 100
    }
  },
  watch: {
    isWalk(val) {
      this.$nextTick(() => {
        const person = document.getElementById('person')
        if (val) {
          person.style.zIndex = 60
        } else {
          console.log('person.style.right', person.style.right, this.personTarget.right)
          if (!person.style.right || person.style.right.slice(0,-2) == this.personTarget.right) {
            person.style.zIndex = 40
          }
        }
      })
    }
  },
  mounted() {
    this.initPersonLocation()
  },
  methods: {
    isEmpty,
    initPersonLocation(){
      const personTarget = document.getElementById('person')
      if (this.isVertical) {
        this.personTarget.bottom = document.body.clientWidth - personTarget.offsetTop - personTarget.offsetHeight
        this.personTarget.right = document.body.clientHeight - personTarget.offsetLeft - personTarget.offsetWidth
      } else {
        this.personTarget.bottom = document.body.clientHeight - personTarget.offsetTop - personTarget.offsetHeight
        this.personTarget.right = document.body.clientWidth - personTarget.offsetLeft - personTarget.offsetWidth
      }
     
    },
    // 吹气动画
    partnerClapGifHandler (target, index) {
      clearInterval(this.timerClap)
      this.timerClap = null
      let i = 0

      const partner = document.getElementById('partner')
      partner.style.display = 'block'
      this.timerClap = setInterval(() => {
        // 前面17张走路
        if (i>=this.clapImgLength) {
          clearInterval(this.timerClap)
          this.timerClap = null
          partner.style.display = 'none'
          this.boardGifHandler(target, index)
          // this.isWalk = false
        } else {
          i=i+1
          partner.src =`https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game2-9/clap/${i}.png`
        }
      }, 60)
    },
    // 走路动画
    personWalkGifHandler () {
      clearInterval(this.timerWalk)
      this.timerWalk = null
      let i = 0
      const person = document.getElementById('person')
      this.timerWalk = setInterval(() => {
        this.isWalk = true
        // 前面17张走路
        if (i>=this.workImgLength) {
          clearInterval(this.timerWalk)
          this.timerWalk = null
          // this.isWalk = false
        } else {
          i=i+1
          person.src =`https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game2-9/walk/${i}.png`
        }
      }, 60)
    },
    // 挖宝动画
    personDigGifHandler (id, index) {
      clearInterval(this.timerDig)
      this.timerDig = null
      let i = this.workImgLength
      const person = document.getElementById('person')
      this.timerDig = setInterval(() => {
        this.isWalk = true
        if (i==38) {
          // document.getElementById('hecaiAudio').play()
          this.partnerClapGifHandler(`${id}`, index)
          this.hecaiAudioVoice.play()
        }
        if (i==58) {
          // document.getElementById('successAudio').play()
          this.successAudioVoice.play()
        }
        if (i >= this.digImgLength -1) {
          clearInterval(this.timerDig)
          this.timerDig = null
          // this.isWalk = false
          // person.src = `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game2-9/walk/${0}.png`
        } else {
          i=i+1
          person.src =`https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game2-9/walk/${i}.png`
        }
      }, 60)
    },
    // 面板动画
    boardGifHandler (id, index) {
      clearInterval(this.timerBoard)
      this.timerBoard = null
     
      const animeType = this.animeTypes
      const person = document.getElementById('person')
      const partnerTarget = document.querySelector(`#partner`)
      let i = 0
      const wordTarget = document.querySelector(`#${id} div`)
      const wrapTarget = document.querySelector(`#${id}`)

      wordTarget.style.display = 'none'
      

      const target = document.querySelector(`#${id} .flower`)
      this.timerBoard = setInterval(() => {
        this.isWalk = true
        if (i>=this.boardImgLength-1) {
          clearInterval(this.timerBoard)
          this.timerBoard = null
          // 隐藏花
          target.style.display = 'none'
          wrapTarget.style.visibility = 'hidden'

          this.delayTimer = setTimeout(() => {
            this.isWalk = false
            this.showWordList[index].clicked = false
            person.src = `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game2-9/walk/${0}.png`
            person.style.left = null
            person.style.top = null
            person.style.bottom = `${this.personTarget.bottom}px`
            person.style.right = `${this.personTarget.right}px`
            partnerTarget.style.bottom = 0
            partnerTarget.style.right = 0
            // 下一个字包括音效 
            this.getData(true)
          }, 1500)
        } else {
          i=i+1
          target.src = `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game2-9/anime/type${animeType}/${i}.png`
          // target.src = `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game2-9/anime/type${animeType}/${i}.png`
        }
      }, 60)
    },
    // 打乱文字排序
    async reverseHandler() {
      if (!this.isWalk){
        clearInterval(this.timerWalk)
        this.timerWalk = null
        clearInterval(this.timerSigh)
        this.timerSigh = null
        clearInterval(this.timerClap)
        this.timerClap = null
        clearInterval(this.timerDig)
        this.timerDig = null
        clearInterval(this.timerBoard)
        this.timerBoard = null

        const person = document.getElementById('person')
        person.style.left = null
        person.style.top = null
        person.style.bottom = `${this.personTarget.bottom}px`
        person.style.right = `${this.personTarget.right}px`
        
        

        this.audioList = []
        await this.initData()
        
        this.showWordList = shuffle(this.InitWordList)
        this.wordList = cloneDeep(this.showWordList)
        this.$set(this, 'showWordList', shuffle(this.InitWordList)) 
        this.$set(this, 'wordList', cloneDeep(this.showWordList)) 
        for(let i =0;i<this.InitWordList.length;i++) {
          const word = document.getElementById(`word${i}`)
          const flowerTarget = document.querySelector(`#word${i} .flower`)
          const wordTarget = document.querySelector(`#word${i} div`)
          word.style.visibility = 'visible'
          wordTarget.style.display = 'block'
          flowerTarget.style.display = 'block'
          flowerTarget.src = `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game2-9/anime/type0/${0}.png`
        }
         for(let i =0;i<this.showWordList.length;i++) {
          this.showWordList[i].clicked = false
          const word = document.getElementById(`word${i}`)
          const flowerTarget = document.querySelector(`#word${i} .flower`)
          const wordTarget = document.querySelector(`#word${i} div`)
          word.style.visibility = 'visible'
          wordTarget.style.display = 'block'
          flowerTarget.style.display = 'block'
          flowerTarget.src = `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game2-9/anime/type0/${0}.png`
        }
        // 一切重新开始
        this.getData()
        this.startGameHandler()
      }
    },
    // 字点击
    wordClickHandler(event, word, index) {
      console.log('word', word, event.target.className)
      console.log('isWalk', this.isWalk)

      if (this.isWalk) return

      let parentNode = event.target.parentElement
      if (event.target.className == 'icon-img') {
        parentNode = event.target.parentElement.parentElement
      } else {
        parentNode = event.target.parentElement
      }
      anime({
        targets: parentNode,
        scale: [
          { value: 1, duration: 100 },
          { value: 1.1, duration: 100 },
          { value: 1, duration: 100 }
        ],
        complete: () => {
          parentNode.style.transform = 'none'
        }
      });
      
      if (this.currentWord.name !== word.name) {
        this.playClickAudio()
        this.playFailAudio()
        
        return false
      }

      this.showWordList[index].clicked = true

      this.playClickAudio()

      const target = document.getElementById(`word${index}`)
      const person = document.getElementById('person')
      
      this.personWalkGifHandler()
      // 文字消失
      const wordTarget = document.querySelector(`#word${index} div`)
      wordTarget.style.display = 'none'
      // 变换成第一个花苞
      const flowerTarget = document.querySelector(`#word${index} .flower`)
      flowerTarget.src = `https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game2-9/anime/type0/${1}.png`
      const partnerTarget = document.querySelector(`#partner`)
      partnerTarget.style.zIndex = 59
      partnerTarget.style.bottom = this.isVertical ? `${document.body.clientWidth - target.offsetTop - target.offsetHeight + person.offsetHeight*0.3}px` : `${document.body.clientHeight - target.offsetTop - target.offsetHeight + person.offsetHeight*0.3}px`,
      partnerTarget.style.right = this.isVertical ? `${document.body.clientHeight - target.offsetLeft - target.offsetWidth - person.offsetWidth/2+person.offsetWidth*0.25}px` : `${document.body.clientWidth - target.offsetLeft - target.offsetWidth - person.offsetWidth/2+person.offsetWidth*0.25}px`
      console.log('isVertical', this.isVertical)
      this.animation = anime({
        targets: person,
        bottom: this.isVertical ? `${document.body.clientWidth - target.offsetTop - target.offsetHeight}px` : `${document.body.clientHeight - target.offsetTop - target.offsetHeight}px`,
        right: this.isVertical ? `${document.body.clientHeight - target.offsetLeft - target.offsetWidth - person.offsetWidth/2-40}px` : `${document.body.clientWidth - target.offsetLeft - target.offsetWidth - person.offsetWidth/2-40}px`,
        easing: 'easeInOutQuad',
        duration: this.workImgLength*60, // 走路多久到达的时间 走路的动作乘以时间
        complete: () => {
         
          // target.innerHTML = '到达'
          clearInterval(this.timerWalk)
          this.personDigGifHandler(`word${index}`, index)
          
          
          
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.v-game-course{
  // font-family: 'Alibaba-PuHuiTi-Bold';
  // font-family: 'FZHuPo-M04S';
audio {
  position: absolute;
  top: -1000px;
  left: -1000px;
  z-index: -1;
}
.mask-dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  // background: url(~https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game2-9/bg.png) center center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  overflow: hidden;
  .overlay-bg {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
  }
   .mask-title {
    border: solid 3px #5f0001;
    color: #5f0001;
    font-size: 50px;
    padding: 20px 0;
    text-align: center;
    background: #fff;
    border-radius: 60px;
    width: 76%;
    margin: 5% auto;
  }
  .start {
    display: block;
    width: 124px;
  }
  .btn-restart {
    width: 223.33px;
  }
}

.home {
  height: 100%;
  width: 100%;
  background: url(https://image.yuanyuanwenhua.com/literacy/static/img/allGame/game2-9/bg.png) center center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  overflow: hidden;
}
// .logo {
//   width: 12px;
//   left: 5px;
//   top: 10PX;
//   position: fixed;
// }
.sound-box-top {
  width: 46px;
  right: 20px;
  top: 10PX;
  z-index: 990;
  position: fixed;
  > img {
    display: block;
    width: 46px;
    & + img {
      margin-top: 10px;
    }
  }
}
.sound-box-bottom {
  z-index: 990;
  width: 46px;
  right: 5px;
  bottom: 5px;
  position: fixed;
  > img {
    display: block;
    width: 46px;
    & + img {
      margin-top: 10px;
    }
  }
}
.word-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  height: 324px;
  &:nth-of-type(1) {
    // margin-left: 120px;
    margin-top: -19%;
    margin-left: 16%;
  }
  &:nth-of-type(2) {
    margin-top: -24%;;
    margin-left: 16%;
  }
  .word {
    transform-origin: center;
    width: 490px;
    // height: 324px;
    font-size: 30px;
    margin-left: -400px;
    // font-weight: bolder;
    position: relative;
    .flower {
      position: absolute; 
      width: 100%;
      // width: 319.5px;
      // height: 416.5px;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
    }
    & + .word {
      margin-left: -320px;
    }
    .icon {
      z-index: 60;
      display: block;
      width: 26%;
      height: 45%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      left: 87%;
      transform: translateX(-50%);
      bottom: -5%;
    }
    .icon-img {
      z-index: 60;
      display: block;
      width: 60px;
      height: 60px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      right: 4%;
      transform: translateX(-50%);
      bottom: 42%;
    }
  }
}
.home-horizontal {
  .word-container {
    &:nth-of-type(1) {
      // margin-left: 120px;
      margin-top:-24vh;
    }
  }
}
#person {
  width: 164.66px;
  height: 157.33px;
  position: fixed;
  right: 30px;
  bottom: 11%;
  z-index: 40;
}
#partner {
  width: 142.16px;
  height: 87px;
  position: fixed;
  right: 0px;
  bottom: 0px;
  z-index: 99;
  display: none;
}
@media screen and (min-width: 540px) {
  .word-container {
    &:nth-of-type(1) {
      // margin-left: 120px;
      margin-top:5vw;
    }
  }
}
}
</style>